import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { extname } from 'path';
// config
import { SITE_CONFIG } from 'config';
// Firebase
import { firebase, functions } from '../../config/firebase';

const storage = firebase.storage();

const initialState = {
  isLoading: false,
  progress: 0,
  error: false,
  mediaItems: [],
  grandLodgeMediaItems: [],
  files: [],
  selectedMediaItems: [],
  currentMedia: null
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START UPLOADING
    startUploading(state, action) {
      state.progress = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEDIAS
    getMediaItemsSuccess(state, action) {
      state.isLoading = false;
      state.mediaItems = action.payload;
    },

    // GET GRAND LODGE MEDIAS
    getGrandLodgeMediaItemsSuccess(state, action) {
      state.isLoading = false;
      state.grandLodgeMediaItems = action.payload;
    },

    // GET FILES
    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // // DELETE MEDIA
    deleteMediaSuccess(state, action) {
      const { name } = action.payload;
      state.isLoading = false;
      state.mediaItems = state.mediaItems.filter((item) => item.name !== name);
    },

    // SET SELECTED FILES
    onSetSelectedMediaItemsSuccess(state, action) {
      state.selectedMediaItems = action.payload;
    },

    // SET SELECTED MEDIA ITEMS
    setSelectedMediaItems(state, action) {
      state.selectedMediaItems = action.payload;
    },

    // UPDATE MEDIA ITEMS AFTER UPLOAD
    updateMediaItems(state, action) {
      const newFile = action.payload;
      state.mediaItems = [...state.mediaItems, newFile];
    },

    // UPDATE MEDIA ITEMS AFTER UPLOAD
    updateFileItems(state, action) {
      const newFile = action.payload;
      state.files = [...state.files, newFile];
    }
  }
});

// Reducer
export default slice.reducer;

export const { updateMediaItems, updateFileItems, setSelectedMediaItems } = slice.actions;

// deleteMediaItems in storage-----------------------//
export function deleteMedia(file) {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    const { name } = file;
    try {
      const originalFileName = name.split('_')[1];
      const originalExt = extname(name);
      const mediaRef = storage.ref(`clients/${client.currentClient.id}/media/${originalFileName}${originalExt}`);
      const mediaThumbnailRef = storage.ref(`clients/${client.currentClient.id}/media/thumbnails/${name}`);

      // Delete both full res and thumbnail files
      await Promise.all([mediaThumbnailRef.delete(), mediaRef.delete()]);
      dispatch(slice.actions.deleteMediaSuccess(file));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getMediaItems in storage-----------------------//
/* eslint-disable */

export function getMediaItems() {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    
    try {
      const storageRef = storage.ref();
      const clientId = client.currentClient.id;
      const mediaPath = `clients/${clientId}/media`;
      const thumbnailsPath = `${mediaPath}/thumbnails`;
      
      // First check if thumbnails exist
      const thumbnailsRef = storageRef.child(thumbnailsPath);
      const thumbnailsList = await thumbnailsRef.listAll();
      
      // If no thumbnails found, attempt regeneration
      if (thumbnailsList.items.length === 0) {
        console.log('No thumbnails found, attempting regeneration...');
        
        // Get original images
        const imagesRef = storageRef.child(mediaPath);
        const imagesList = await imagesRef.listAll();
        const originalImages = imagesList.items.filter(item => !item.name.includes('thumb_'));
        
        if (originalImages.length > 0) {
          console.log(`Found ${originalImages.length} original images, regenerating thumbnails...`);
          
          // Regenerate thumbnails for each image
          const regenerateThumbnail = functions.httpsCallable('regenerateThumbnail');
          for (const imageRef of originalImages) {
            try {
              await regenerateThumbnail({ filePath: imageRef.fullPath });
              console.log(`Generated thumbnail for: ${imageRef.name}`);
            } catch (error) {
              console.error(`Failed to generate thumbnail for ${imageRef.name}:`, error);
            }
          }
          
          // Refresh thumbnails list after regeneration
          const updatedThumbnailsList = await thumbnailsRef.listAll();
          thumbnailsList.items = updatedThumbnailsList.items;
        }
      }
      
      // Process thumbnails as before
      let fileReads = [];
      if (thumbnailsList.items.length >= 1) {
        fileReads = thumbnailsList.items.map(async (fileRef) => {
          try {
            const metaData = await fileRef.getMetadata();
            const downloadUrl = await fileRef.getDownloadURL();
            return { ...metaData, downloadUrl };
          } catch (itemError) {
            console.error('Error processing thumbnail:', fileRef.name, itemError);
            return null;
          }
        });
      }
      
      const result = await Promise.all(fileReads);
      const validResults = result.filter(item => item !== null);
      
      dispatch(slice.actions.getMediaItemsSuccess(validResults));
      
      // Return information about the regeneration process
      return {
        originalCount: thumbnailsList.items.length,
        regeneratedCount: validResults.length,
        success: true
      };
      
    } catch (error) {
      console.error('Error in getMediaItems:', error);
      dispatch(slice.actions.hasError(error));
      return {
        success: false,
        error: error.message
      };
    }
  };
}

// getGrandLodgeMediaItems in storage-----------------------//
export function getGrandLodgeMediaItems() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const grandLodgeId = SITE_CONFIG().ROOT_CLIENT;
      const storageRef = storage.ref();
      let grandLodgeFileReads = [];
      const grandLodgeImagesRef = storageRef.child(`clients/${grandLodgeId}/media/thumbnails`);
      const grandLodgeList = await grandLodgeImagesRef.listAll();

      grandLodgeFileReads = grandLodgeList.items.map(async (fileRef) => {
        const metaData = await fileRef.getMetadata();
        const downloadUrl = await fileRef.getDownloadURL();
        return { ...metaData, downloadUrl };
      });

      const result = await Promise.all(grandLodgeFileReads);

      dispatch(slice.actions.getGrandLodgeMediaItemsSuccess(result));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getFileItems in storage-----------------------
export function getFileItems() {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    try {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`clients/${client.currentClient.id}/media/files`);
      const fileList = await fileRef.listAll();
      let fileReads = [];
      if (fileList.items.length >= 1) {
        fileReads = fileList.items.map(async (fileRef) => {
          const metaData = await fileRef.getMetadata();
          const downloadUrl = await fileRef.getDownloadURL();
          return { ...metaData, downloadUrl };
        });
      }
      const result = await Promise.all(fileReads);
      dispatch(slice.actions.getFilesSuccess(result));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
